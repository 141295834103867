.inputSearchDashboard{
  display: flex;

  flex-direction: column;
  gap: 10px;
  position: relative;
}
.inputSearchDashboard .modalInput{
  height: auto
}
.search{
  padding: 3px 4px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 40vh;
  border: 1px solid #d1cccc;
}
.search input{
  padding: 3px ;
  width: 100%;
  border: none;
}

.modalInput{
  z-index: 2;
  position: absolute;
  top: 50px;
  background-color: var(--blanco);
  padding: 10px;
  border-radius: 10px;
  width: 40vh;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.114);
}
.link {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  color: var(--text-color2);
}
.link:hover{
  background-color: var(--color1);
  color: var(--blanco);
}

@media (max-width: 1024px){
  .search{
      
      width: 45vh;
     
  }
  .modalInput{
      width: 100%;
  }
}